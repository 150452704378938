@import url("https://fonts.googleapis.com/css?family=Fira+Sans:400,500,600,700,800");

* {
  box-sizing: border-box;
}

.blog-slider {
  width: 90%;
  position: relative;
  margin: auto;
  background: #fff;
  box-shadow: 0px 7px 40px rgba(34, 35, 58, 0.2);
  border-radius: 25px;
  height: 160px;
  transition: all 0.3s;
}

@media screen and (max-width: 992px) {
  .blog-slider {
    max-width: 680px;
    height: 160px;
  }
}

.blog-slider__item {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .blog-slider__item {
    flex-direction: column;
  }
}

.blog-slider__img {
  width: 60px;
  flex-shrink: 0;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  transform: translateX(-30px) translateY(20px);
  overflow: hidden;
}

@media screen and (max-width: 768px) {
  .blog-slider__img {
    transform: translateY(-50%);
  }
}

.blog-slider__content {
  padding-right: 25px;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .blog-slider__content {
    margin-top: -40px;
    text-align: center;
    padding: 20px 15px;
  }
  .blog-slider{
    height: auto;
  }
}

.blog-slider__content > * {
  opacity: 0;
  transform: translateY(20px);
  transition: all 0.4s;
  animation: fade-in 2s forwards;
}

@keyframes fade-in {
  0%   { opacity: 0; }
  25%  { opacity: 0.25; }
  50%  { opacity: 0.5; }
  75%  { opacity: 0.75; }
  100% { opacity: 1; }
}

.blog-slider__code {
  color: #7b7992;
  margin-bottom: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-weight: 500;
}

.blog-slider__title {
  font-weight: 700;
  color: #0d0925;
  margin-bottom: 10px;
}

.blog-slider__text {
  color: #4e4a67;
  margin-bottom: 15px;
  line-height: 1.5em;
}
