
.card__img img {
    width: 100%;
    display: block;
    height: 100%;
    object-fit: cover;
}
  
.demo-section {
    display: grid;
    grid-template-columns: 25% 30% 15% 25%;
    gap: 15px;
    place-content: center;
    grid-template-rows: 50% 50%;
    height: 80vh;
    min-height: 460px;
    padding: max(2vh, 1.5rem);
    overflow: hidden;
}
@media screen and (max-width: 690px) {
    .demo-section {
        height: 65vh;
    }
}
.demo-section .card {
    border-radius: 25px;
    box-shadow: -2px 4px 15px rgba(0, 0, 0, 0.26);
}
.demo-section .card:nth-child(2) {
    grid-column: 2/3;
    grid-row: span 2;
}
@media screen and (max-width: 690px) {
    .demo-section .card:nth-child(2) {
        grid-column: span 1;
        grid-row: span 1;
    }
}
.demo-section .card:nth-child(3) {
    grid-column: span 2;
}
@media screen and (max-width: 690px) {
    .demo-section .card:nth-child(3) {
        grid-column: 1/4;
        grid-row: 1/2;
    }
}
.demo-section .card:nth-child(5) {
    grid-column: span 2;
}
.demo-section .card p {
    font-size: clamp(0.9rem, 0.8750rem + 0.1250vw, 1rem);
    line-height: 1.4;
}
.demo-section .card img {
    border-radius: 25px;
}
.demo-section .card .card__img {
    border-radius: 25px;
    position: relative;
    overflow: hidden;
    height: 100%;
}
.demo-section .card .card__img .card__overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    content: "";
    color: #fff;
    padding: clamp(0.938rem,5vw,1.563rem);
    background: #02022e;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.57) 0%, rgba(255, 255, 255, 0) 100%);
    width: 100%;
    height: 100%;
    border-radius: 25px;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
}
.demo-section .card .card__img span {
    position: absolute;
    top: 25px;
    left: min(2vmax, 1.563rem);
    color: #ff7b29;
    background: #fff;
    border-radius: 50px;
    padding: 2px 8px 2px 6px;
    display: flex;
    box-shadow: 0px 1px 20px #0000002b;
}
@media screen and (max-width: 690px) {
    .demo-section .card .card__img span {
        top: 20px;
    }
}
.demo-section .card .card__img span svg {
    fill: #ff7b29;
    width: 20px;
    margin-right: 2px;
}

.card .title {
    opacity: 0;
    position: absolute;
    top: 50%;
    width: 100%;
    text-align: center;
}

.card:hover .title{
    transform: translate(0,-50%);
    opacity: 1;
    transition: all 1s;
}
.card:hover img{
    transform: scale(1.1);
    filter: brightness(55%);
}
.card img{
    transition: transform 0.3s ease-in-out;
}
.card:hover{
    cursor: pointer;
}

@media screen and (max-width: 470px) {
.demo-section {
    display: flex;
    flex-direction: column;
    margin: auto;
    height: auto;
}

.demo-section .card{
    margin-top: 4px;
    margin-bottom: 4px;
    height: 200px;
}

.demo-section .card:nth-child(2) {
    grid-column: span 1;
    grid-row: span 2;
}

.demo-section .card:nth-child(3) {
    grid-column: span 1;
    grid-row: span 1;
}

.demo-section .card:nth-child(4) {
    grid-column: span 1;
    grid-row: span 1;
}

.demo-section .card:nth-child(5) {
    grid-column: span 1;
    grid-row: span 1;
}
}

.trip_plan_main{
    min-height: 100vh;
    height: auto;
    background: linear-gradient(
        rgba(0, 0, 0, 0.3),
        rgba(0, 0, 0, 0.3)
      ),url("../../assets/tempbg1.jpg");
    background-size: cover;
    background-repeat: no-repeat;
}

.formcard, .glass{
    background: rgba(255, 255, 255, 0.1);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
}

@media screen and (max-width: 690px) {
    .trip_plan_main {
        background: linear-gradient(
        rgba(0, 0, 0, 0.3),
        rgba(0, 0, 0, 0.3)
        ),url("../../assets/tempbg2.jpg");
        background-size: cover;
        background-repeat: no-repeat;
    }
    .formcard{
        max-height: calc(100% * 5/6);
        overflow: scroll;
        margin: 20px auto;
    }
}

.tripplanner-title{
    background: -webkit-linear-gradient(#fcb814, #ff7b29);
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.range-slider {
    position: relative;
    width: 200px;
  }
  
  .slider {
    width: 100%;
    height: 8px;
    background: white;
    border-radius: 5px;
    outline: none;
    opacity: 0.7;
    -webkit-transition: .2s;
    transition: opacity .2s;
  }
  
  .slider:hover {
    opacity: 1;
  }
  
  .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 18px;
    height: 18px;
    background: #4CAF50;
    border-radius: 50%;
    cursor: pointer;
  }
  
  .slider::-moz-range-thumb {
    width: 18px;
    height: 18px;
    background: #4CAF50;
    border-radius: 50%;
    cursor: pointer;
  }
  
  .range-values {
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
  }
  
  .range-values span {
    font-size: 12px;
    color: white;
  }
  