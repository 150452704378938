#box {
    background: url(https://cdn.rawgit.com/ManzDev/cursos-assets/gh-pages/css3/earth-2.jpg);
    background-size: cover;
    border: 2px solid #000;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: movimiento 25s linear 0s infinite;
    box-shadow: 0 0 5px RGBA(255, 255, 255, 0.10),
                -2px -2px 3px #000 inset,
                1px 1px 3px #000 inset,
                -11px -11px 5px RGBA(0, 0, 0, 0.5) inset,
                6px 6px 11px RGBA(0, 0, 0, 0.45) inset;
    transform: rotateX(6deg) rotateY(6deg) rotateZ(6deg);
}

  
  @keyframes movimiento {
    0% { background-position:0 0 }
    100% { background-position:355px 0 }
  }

  .highlight-container, .highlight {
    position: relative;
  }
  
  .highlight-container {
    display: inline-block;
  }
  .highlight-container:before {
    content: " ";
    display: block;
    height: 100%;
    width: 100%;
    margin-left: -3px;
    margin-right: -3px;
    position: absolute;
    background: #ffd500;
    transform: rotate(2deg);
    top: -1px;
    left: -1px;
    border-radius: 20% 25% 20% 24%;
    padding: 10px 3px 3px 10px;
  }

  .tour-content-block{
    border-radius: 0.5rem;
    background-image: url('../../assets/tempbg8.jpg');
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 380px;
  }

  .tour-help,.tour-help-inner{
    width: 100%;
    height: 100%;
  }
  
  .tour-help-inner{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding-top: 3rem;
    padding-bottom: 3rem;
    padding-left: 1.5rem;
    padding-right: 2.5rem;
  }
  
  .tour-help-inner::before{
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
    background-image: linear-gradient(to right, #3a3737, #6f6330);
    opacity: 0.5;
    content: '';
  }
  
  @media (min-width: 1400px){
    .tour-help-inner{
      flex-direction: row;
    }
  
    .tour-help-inner{
      align-items: center;
    }
  }
  
  .tour-help-content, .tour-help-call{
    position: relative;
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
  }
  
  .tour-help-content{
    width: 100%;
  }
  
  @media (min-width: 1400px){
    .tour-help-content{
      width: calc(100% - 300px);
    }
  }
  
  .tour-help-title{
    font-size: 1.25rem;
    line-height: 1.75rem;
    font-weight: 600;
  }
  
  @media (min-width: 992px){
    .tour-help-title{
      font-size: 1.875rem;
      line-height: 2.25rem;
    }
  }
  
  .tour-help-text{
    margin-top: 1.25rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
    opacity: 0.95;
  }
  
  @media (min-width: 992px){
    .tour-help-text{
      font-size: 1rem;
      line-height: 1.5rem;
    }
  }
  
  .tour-help-call{
    margin-top: 2.5rem;
    display: flex;
    cursor: pointer;
    align-items: center;
    border-radius: 0.375rem;
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 500;
    letter-spacing: 0.025em;
    --tw-text-opacity: 1;
    color: rgb(71 85 105 / var(--tw-text-opacity));
  }
  
  @media (min-width: 992px){
    .tour-help-call{
      padding-left: 1.25rem;
      padding-right: 1.25rem;
    }
  
    .tour-help-call{
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
  
    .tour-help-call{
      font-size: 1rem;
      line-height: 1.5rem;
    }
  }
  
  @media (min-width: 1400px){
    .tour-help-call{
      margin-top: 0px;
    }
  }
  
  .tour-help-call [class*="material"]{
    margin-right: 0.5rem;
    --tw-text-opacity: 1;
    color: rgb(253 186 116 / var(--tw-text-opacity));
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
  }